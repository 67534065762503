import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { authGuard } from '@repo/vue/plugins';
import { clientGuard } from '@/shared/guards/client.guard';
import AppLayout from '@/layout/AppLayout.vue';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: AppLayout,
    children: [
      {
        path: '/',
        component: () => import('@/layout/AppMainLayout.vue'),
        beforeEnter: authGuard,
        children: [
          {
            path: '',
            name: 'main',
            redirect: '/clients'
          },
          {
            path: 'clients',
            name: 'clients',
            component: () => import('@/views/main/client/client-listing/ClientListingView.vue')
          },
          {
            path: '',
            beforeEnter: clientGuard,
            component: () => import('@/layout/AppClientLayout.vue'),
            children: [
              {
                path: 'process',
                name: 'process',
                component: () => import('@/views/main/client/process/ClientProcessView.vue')
              },
              {
                path: 'dashboard',
                name: 'dashboard',
                component: () => import('@/views/pages/DashboardView.vue')
              },
              {
                path: 'document',
                name: 'pdfViewer',
                meta: {
                  component: 'PdfViewer'
                },
                component: () => import('@/views/main/documents/PdfViewer.vue')
              },
              {
                path: 'demos',
                name: 'demos',
                component: () => import('@/views/demos/DemosView.vue'),
                children: [
                  {
                    path: 'progress-demo',
                    name: 'progress-demo',
                    component: () => import('@/views/demos/progress/ProgressDemo.vue')
                  },
                  {
                    path: 'broadcast-channel-demo',
                    name: 'broadcast-channel-demo',
                    component: () => import('@/views/demos/broadcast-channel/BroadcastChannelDemo.vue')
                  },
                  {
                    path: 'message-event-basic-request-demo',
                    name: 'message-event-basic-request-demo',
                    component: () => import('@/views/demos/message-event-basic/MessageEventRequestDemo.vue')
                  },
                  {
                    path: 'message-event-basic-response-demo',
                    name: 'message-event-basic-response-demo',
                    component: () => import('@/views/demos/message-event-basic/MessageEventResponseDemo.vue')
                  },
                  {
                    path: 'message-event-multiple-request-demo',
                    name: 'message-event-multiple-request-demo',
                    component: () => import('@/views/demos/message-event-multiple/MessageEventRequestDemo.vue')
                  },
                  {
                    path: 'message-event-multiple-response-demo',
                    name: 'message-event-multiple-response-demo',
                    component: () => import('@/views/demos/message-event-multiple/MessageEventResponseDemo.vue')
                  },
                  {
                    path: 'data-grid-demo',
                    name: 'data-grid-demo',
                    component: () => import('@/views/demos/data-grid/DataGridDemo.vue')
                  },
                  {
                    path: 'data-table-demo',
                    name: 'data-table-demo',
                    component: () => import('@/views/demos/data-table/DataTableDemo.vue')
                  },
                  {
                    path: 'data-grid-demo',
                    name: 'data-grid-demo',
                    component: () => import('@/views/demos/data-grid/DataGridDemo.vue')
                  },
                  {
                    path: 'startup-demo',
                    name: 'startup-demo',
                    component: () => import('@/views/demos/startup/StartupDemo.vue')
                  },
                  {
                    path: 'select-demo',
                    name: 'select-demo',
                    component: () => import('@/views/demos/select/DxSelectDemo.vue')
                  }
                ]
              },
              {
                path: 'client-setup',
                children: [
                  {
                    path: '',
                    name: 'clientSetup',
                    meta: {
                      menuItemId: '2f2581f5-2da9-43ae-b848-45f2c87d0cc0',
                      menuType: 1,
                      component: 'ClientSetupView'
                    },
                    component: () => import('@/views/main/client/process/client-setup/ClientSetupView.vue')
                  },
                  {
                    path: 'electronic-review',
                    name: 'electronicReview',
                    meta: {
                      menuItemId: 'ec194f9b-8996-40ab-bffa-740074e2cbd2',
                      component: 'ElectronicReviewView'
                    },
                    component: () => import('@/views/main/client/process/electronic-review/ElectronicReviewView.vue')
                  },
                  {
                    path: 'tick-marks',
                    name: 'tickMarks',
                    meta: {
                      menuItemId: '8067d86a-802c-4ca7-ae9f-f7143252139e',
                      component: 'TickMarksView'
                    },
                    // component: () => import('@repo/vue/modules').then((m) => m.TickMarksView)
                    component: () => import('@/views/main/client/process/tick-marks/TickMarksView.vue')
                  },
                  {
                    path: 'switch-framework',
                    name: 'frameworkOrMethodologySwitch',
                    meta: {
                      menuItemId: '9033cbe8-5034-4895-ad0c-a1e0ad7fd365',
                      menuType: 2,
                      component: 'SwitchFrameworkView'
                    },
                    component: () => import('@/views/main/client/process/framework-switch/SwitchFrameworkModal.vue')
                  },
                  {
                    path: 'roll-forward',
                    name: 'rollForward',
                    meta: {
                      menuItemId: '09485bd1-8bfb-439f-a0fc-cec4d5d504b7',
                      menuType: 2,
                      component: 'RollForwardView'
                    },
                    component: () => import('@/views/main/client/process/roll-forward/RollForwardModal.vue')
                  },
                  {
                    path: 'startup',
                    name: 'startup',
                    meta: {
                      menuItemId: '8c57f9e1-3c60-4dd1-9d07-08ac4427e0ef',
                      menuType: 1,
                      component: 'StartupView'
                    },
                    component: () => import('@/views/main/client/process/startup/StartupView.vue')
                  },
                  {
                    path: 'notice',
                    name: 'notice',
                    meta: {
                      menuItemId: '64e6113a-f0b9-467c-a5ad-691316996544',
                      menuType: 1,
                      component: 'NoticeView'
                    },
                    component: () => import('@/views/main/client/process/notice/NoticeView.vue')
                  }
                ]
              },
              {
                path: 'financial-data',
                children: [
                  {
                    path: 'working-trial-balance',
                    name: 'workingTrialBalance',
                    meta: {
                      menuItemId: '96ac9b7d-b799-470f-85ea-54c9dd02d113',
                      menuType: 1,
                      component: 'WorkingTrialBalanceView'
                    },
                    // component: () => import('@repo/vue/modules').then((m) => m.WorkingTrialBalanceView)
                    component: () =>
                      import('@/views/main/client/process/working-trial-balance/WorkingTrialBalanceView.vue')
                  },
                  {
                    path: 'journal-entries',
                    name: 'journalEntries',
                    meta: {
                      menuItemId: 'fff6f585-869b-4a47-a5fc-bc8c5df47956',
                      menuType: 1,
                      component: 'JournalEntriesView'
                    },
                    component: () => import('@/views/main/client/process/journal-entries/JournalEntriesView.vue')
                  },
                  {
                    path: 'cashbook-entries',
                    name: 'cashbookEntries',
                    meta: {
                      menuItemId: '7765b360-4472-4921-919d-af32cde4eb91',
                      menuType: 1,
                      component: 'CashBookEntriesView'
                    },
                    component: () => import('@/views/main/client/process/cashbook-entries/CashBookEntriesView.vue')
                  }
                ]
              },
              {
                path: 'financials',
                name: 'spreadsheet',
                meta: {
                  component: 'SpreadsheetView'
                },
                component: () => import('@/views/main/spreadsheet/SpreadsheetView.vue')
              },
              {
                path: 'reports',
                children: [
                  {
                    path: 'final-trial-balance-report',
                    name: 'trialBalanceReport',
                    meta: {
                      menuItemId: '2faab65c-0bcb-4f55-acaf-0ef3c9fe3b9e',
                      menuType: 1,
                      allowMultiple: true,
                      component: 'TrialBalanceReportView'
                    },
                    // component: () => import('@repo/vue/modules').then((m) => m.TrialBalanceReportView)
                    component: () => import('@/views/main/reports/trial-balance-report/TrialBalanceReportView.vue')
                  },
                  {
                    path: 'journal-report',
                    name: 'journalReport',
                    meta: {
                      menuItemId: '61166a5e-746a-4f42-8b4b-cc962fcf909d',
                      menuType: 1,
                      allowMultiple: true,
                      component: 'JournalReportView'
                    },
                    // component: () => import('@repo/vue/modules').then((m) => m.JournalReportView)
                    component: () => import('@/views/main/reports/journal-report/JournalReportView.vue')
                  },
                  {
                    path: 'cashbook-report',
                    name: 'cashbookReport',
                    meta: {
                      menuItemId: 'dd63d276-0a81-4345-a57d-0c9e19b3c093',
                      menuType: 1,
                      allowMultiple: true,
                      component: 'CashbookReportView'
                    },
                    // component: () => import('@repo/vue/modules').then((m) => m.CashbookReportView)
                    component: () => import('@/views/main/reports/cashbook-report/CashbookReportView.vue')
                  },
                  {
                    path: 'general-ledger-report',
                    name: 'generalLedgerReport',
                    meta: {
                      menuItemId: '84d6e586-b1ff-4d9f-acf4-be49e2f9c22c',
                      menuType: 1,
                      allowMultiple: true,
                      component: 'GeneralLedgerReportView'
                    },
                    // component: () => import('@repo/vue/modules').then((m) => m.GeneralLedgerReportView)
                    component: () => import('@/views/main/reports/general-ledger-report/GeneralLedgerReportView.vue')
                  },
                  {
                    path: 'account-drill-down',
                    name: 'accountDrillDown',
                    meta: {
                      menuItemId: '810466a2-ade7-4446-8b07-bba1ca041759',
                      menuType: 1,
                      allowMultiple: true,
                      component: 'AccountDrillDownView'
                    },
                    // component: () => import('@repo/vue/modules').then((m) => m.AccountDrillDownView)
                    component: () => import('@/views/main/reports/account-drill-down/AccountDrillDownView.vue')
                  }
                ]
              },
              {
                path: 'xbrl',
                name: 'xbrl',
                meta: {
                  menuItemId: '528a9405-33a4-4c37-8bcf-d1c7be8b30a5',
                  component: 'XbrlView'
                },
                component: () => import('@/views/main/client/process/xbrl/XbrlView.vue')
              }
            ]
          }
        ]
      },
      {
        path: '/admin',
        component: () => import('@/layout/AppAdminLayout.vue'),
        beforeEnter: authGuard,
        children: [
          {
            path: '',
            name: 'admin',
            redirect: '/admin/support'
          },
          {
            path: 'support',
            name: 'admin-support',
            component: () => import('@/views/admin/support/SupportView.vue')
          },
          {
            path: 'manage',
            name: 'admin-manage',
            component: () => import('@/views/admin/management/ManageView.vue')
          },
          {
            path: 'reports',
            name: 'admin-reports',
            component: () => import('@/views/admin/reports/ReportsView.vue')
          },
          {
            path: 'security',
            name: 'admin-security',
            component: () => import('@/views/admin/security/PermissionsView.vue')
          },
          {
            path: 'vouchers',
            name: 'admin-vouchers',
            component: () => import('@/views/admin/vouchers/VouchersView.vue')
          },
          {
            path: 'notifications',
            name: 'admin-notifications',
            component: () => import('@/views/admin/notifications/NotificationListView.vue')
          },
          {
            path: 'notifications/edit/:id?',
            name: 'admin-notifications-edit',
            component: () => import('@/views/admin/notifications/NotificationEditView.vue')
          },
          {
            path: 'development',
            name: 'admin-development',
            component: () => import('@/views/admin/development/DevelopmentView.vue')
          },
          {
            path: 'payments',
            name: 'admin-payments',
            component: () => import('@/views/admin/payments/PaymentsView.vue')
          },
          {
            path: 'maintenance',
            name: 'admin-maintenance',
            component: () => import('@/views/admin/maintenance/MaintenanceListView.vue')
          },
          {
            path: 'maintenance/edit/:id?',
            name: 'admin-maintenance-edit',
            component: () => import('@/views/admin/maintenance/MaintenanceEditView.vue')
          },
          {
            path: ':pathMatch(.*)*',
            name: 'admin-not-found',
            component: () => import('@/views/pages/NotFoundView.vue')
          }
        ]
      },
      {
        path: '/angular',
        name: 'angular',
        component: () => import('@/views/common/angular/AngularView.vue')
      },
      {
        path: '/confirm-email',
        name: 'confirmEmail',
        component: () => import('@/views/common/confirm-email/ConfirmEmailView.vue')
      },
      {
        path: '/confirm-user-invitation',
        name: 'confirmUserInvitation',
        component: () => import('@/views/common/confirm-user-invitation/ConfirmUserInvitation.vue')
      },
      {
        path: '/user-feedback',
        name: 'userFeedback',
        component: () => import('@/views/common/user-feedback/UserFeedbackView.vue')
      },
      {
        path: '/connector-auth-done',
        name: 'connectorAuthDone',
        component: () => import('@/views/common/connector-auth-done/ConnectorAuthDoneView.vue')
      },
      {
        path: '/registration',
        name: 'registration',
        component: () => import('@/views/common/registration/RegistrationView.vue')
      },
      {
        path: '/registration-completed',
        name: 'registrationCompleted',
        component: () => import('@/views/common/registration/RegistrationCompletedView.vue')
      },
      {
        path: '/registration-request-completed',
        name: 'registrationRequestCompleted',
        component: () => import('@/views/common/registration/RegistrationRequestCompletedView.vue')
      }
    ]
  },
  {
    path: '/callback',
    name: 'callback',
    component: () => import('@/views/common/callback/CallBackView.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/pages/ProfileView.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/public',
    name: 'public',
    component: () => import('@/views/pages/PublicView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: () => import('@/views/pages/NotFoundView.vue')
  }
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior: () => ({ top: 0 }),
  routes
});

export default router;
