import { ThemePlugin } from './plugin';

/**
 * Creates the Loader plugin.
 *
 * @returns An instance of LoaderPlugin
 */
export const createTheme = () => {
  return new ThemePlugin();
};
