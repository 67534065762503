import { definePreset } from '@primevue/themes';
import Aura from '@primevue/themes/aura';

export default definePreset(Aura, {
  primitive: {
    borderRadius: '0px',
    green: {
      50: '#f9fcf6',
      100: '#e3f1d4',
      200: '#cde5b1',
      300: '#b7da8f',
      400: '#a1ce6c',
      500: '#8bc34a',
      600: '#76a63f',
      700: '#618934',
      800: '#4c6b29',
      900: '#384e1e',
      950: '#052e16'
    },
    red: {
      50: '#fff7f4',
      100: '#ffd7ca',
      200: '#ffb7a0',
      300: '#ff9776',
      400: '#ff774c',
      500: '#ff5722',
      600: '#d94a1d',
      700: '#b33d18',
      800: '#8c3013',
      900: '#66230e',
      950: '#450a0a'
    },
    orange: {
      50: '#fffaf2',
      100: '#ffe6c2',
      200: '#ffd391',
      300: '#ffbf61',
      400: '#ffac30',
      500: '#ff9800',
      600: '#d98100',
      700: '#b36a00',
      800: '#8c5400',
      900: '#663d00',
      950: '#431407'
    },
    yellow: {
      50: '#fefce8',
      100: '#fef9c3',
      200: '#fef08a',
      300: '#fde047',
      400: '#facc15',
      500: '#eab308',
      600: '#ca8a04',
      700: '#a16207',
      800: '#854d0e',
      900: '#713f12',
      950: '#422006'
    },
    blue: {
      50: '#f7fbfe',
      100: '#d6edfa',
      200: '#b6def6',
      300: '#96cff1',
      400: '#75c1ed',
      500: '#55b2e9',
      600: '#4897c6',
      700: '#3c7da3',
      800: '#2f6280',
      900: '#22475d',
      950: '#172554'
    },
    gray: {
      50: '#f9fafb',
      100: '#f3f4f6',
      200: '#e5e7eb',
      300: '#d1d5db',
      400: '#9ca3af',
      500: '#6b7280',
      600: '#4b5563',
      700: '#374151',
      800: '#1f2937',
      900: '#111827',
      950: '#030712'
    },
    neutral: {
      50: '#fafafa',
      100: '#f5f5f5',
      200: '#e5e5e5',
      300: '#d4d4d4',
      400: '#a3a3a3',
      500: '#737373',
      600: '#525252',
      700: '#404040',
      800: '#262626',
      900: '#171717',
      950: '#121212'
    }
  },
  semantic: {
    primary: {
      50: '{blue.50}',
      100: '{blue.100}',
      200: '{blue.200}',
      300: '{blue.300}',
      400: '{blue.400}',
      500: '{blue.500}',
      600: '{blue.600}',
      700: '{blue.700}',
      800: '{blue.800}',
      900: '{blue.900}',
      950: '{blue.950}'
    },
    list: {
      padding: '0.25rem 0.25rem',
      gap: '2px',
      header: {
        padding: '0.5rem 1rem 0.25rem 1rem'
      },
      option: {
        padding: '0.75rem 0.75rem',
        borderRadius: '{border.radius.sm}'
      },
      optionGroup: {
        padding: '0.5rem 0.75rem',
        fontWeight: '600'
      }
    },
    navigation: {
      list: {
        padding: '0.5rem 0rem',
        gap: '0px'
      },
      item: {
        padding: '0.75rem 1.0rem',
        borderRadius: '{border.radius.sm}',
        gap: '0.5rem'
      },
      submenuLabel: {
        padding: '0.5rem 0.75rem',
        fontWeight: '600'
      },
      submenuIcon: {
        size: '0.875rem'
      }
    },
    colorScheme: {
      light: {
        surface: {
          0: '#ffffff',
          50: '{neutral.50}',
          100: '{neutral.100}',
          200: '{neutral.200}',
          300: '{neutral.300}',
          400: '{neutral.400}',
          500: '{neutral.500}',
          600: '{neutral.600}',
          700: '{neutral.700}',
          800: '{neutral.800}',
          900: '{neutral.900}',
          950: '{neutral.950}'
        },
        primary: {
          color: '{primary.500}',
          contrastColor: '#ffffff',
          hoverColor: '{primary.600}',
          activeColor: '{primary.700}'
        },
        highlight: {
          background: '{primary.50}',
          focusBackground: '{primary.100}',
          color: '{primary.700}',
          focusColor: '{primary.800}'
        },
        formField: {
          background: '{surface.0}',
          disabledBackground: '{surface.200}',
          filledBackground: '{surface.50}',
          filledFocusBackground: '{surface.50}',
          borderColor: '{surface.300}',
          hoverBorderColor: '{surface.400}',
          focusBorderColor: '{primary.color}',
          invalidBorderColor: '{red.400}',
          color: '{surface.700}',
          disabledColor: '{surface.500}',
          placeholderColor: '{surface.500}',
          iconColor: '{surface.400}'
        }
      },
      dark: {
        surface: {
          0: '#ffffff',
          50: '{neutral.50}',
          100: '{neutral.100}',
          200: '{neutral.200}',
          300: '{neutral.300}',
          400: '{neutral.400}',
          500: '{neutral.500}',
          600: '{neutral.600}',
          700: '{neutral.700}',
          800: '{neutral.800}',
          900: '{neutral.900}',
          950: '{neutral.950}'
        },
        primary: {
          color: '{primary.400}',
          contrastColor: '{surface.900}',
          hoverColor: '{primary.300}',
          activeColor: '{primary.200}'
        },
        highlight: {
          background: 'color-mix(in srgb, {primary.400}, transparent 84%)',
          focusBackground: 'color-mix(in srgb, {primary.400}, transparent 76%)',
          color: 'rgba(255,255,255,.87)',
          focusColor: 'rgba(255,255,255,.87)'
        },
        formField: {
          background: '{surface.950}',
          disabledBackground: '{surface.700}',
          filledBackground: '{surface.800}',
          filledFocusBackground: '{surface.800}',
          borderColor: '{surface.700}',
          hoverBorderColor: '{surface.600}',
          focusBorderColor: '{primary.color}',
          invalidBorderColor: '{red.300}',
          color: '{surface.0}',
          disabledColor: '{surface.400}',
          placeholderColor: '{surface.400}',
          iconColor: '{surface.400}'
        }
      }
    }
  }
});
