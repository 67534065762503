import { App } from 'vue';
import { Arya } from '@repo/vue/themes';
import PrimeVue from 'primevue/config';
import DialogService from 'primevue/dialogservice';
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';
import Tooltip from 'primevue/tooltip';
import Ripple from 'primevue/ripple';
import BadgeDirective from 'primevue/badgedirective';
import StyleClass from 'primevue/styleclass';
import AutoComplete from 'primevue/autocomplete';
import Badge from 'primevue/badge';
import BlockUI from 'primevue/blockui';
import Button from 'primevue/button';
import Card from 'primevue/card';
import Chart from 'primevue/chart';
import Checkbox from 'primevue/checkbox';
import Column from 'primevue/column';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmPopup from 'primevue/confirmpopup';
import DynamicDialog from 'primevue/dynamicdialog';
import DataTable from 'primevue/datatable';
import DataView from 'primevue/dataview';
import DatePicker from 'primevue/datepicker';
import Dialog from 'primevue/dialog';
import Drawer from 'primevue/drawer';
import Select from 'primevue/select';
import Editor from 'primevue/editor';
import Fluid from 'primevue/fluid';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import ToggleSwitch from 'primevue/toggleswitch';
import InputText from 'primevue/inputtext';
import Knob from 'primevue/knob';
import Listbox from 'primevue/listbox';
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';
import Panel from 'primevue/panel';
import ProgressBar from 'primevue/progressbar';
import ProgressSpinner from 'primevue/progressspinner';
import RadioButton from 'primevue/radiobutton';
import Rating from 'primevue/rating';
import SelectButton from 'primevue/selectbutton';
import SplitButton from 'primevue/splitbutton';
import Splitter from 'primevue/splitter';
import SplitterPanel from 'primevue/splitterpanel';
import Tabs from 'primevue/tabs';
import TabList from 'primevue/tablist';
import Tab from 'primevue/tab';
import TabMenu from 'primevue/tabmenu';
import TabPanels from 'primevue/tabpanels';
import TabPanel from 'primevue/tabpanel';
import Textarea from 'primevue/textarea';
import Toast from 'primevue/toast';
import Toolbar from 'primevue/toolbar';
import InputGroup from 'primevue/inputgroup';
import InputGroupAddon from 'primevue/inputgroupaddon';

export class ThemePlugin {
  install(app: App) {
    app.use(PrimeVue, {
      theme: {
        preset: Arya,
        options: {
          darkModeSelector: '.app-dark',
          cssLayer: {
            name: 'primevue',
            order: 'tailwind-base, primevue, styles, tailwind-utilities'
          }
        }
      }
    });
    app.use(DialogService);
    app.use(ToastService);
    app.use(ConfirmationService);

    app.directive('tooltip', Tooltip);
    app.directive('ripple', Ripple);
    app.directive('badge', BadgeDirective);
    app.directive('styleclass', StyleClass);

    app.component('AutoComplete', AutoComplete);
    app.component('Badge', Badge);
    app.component('BlockUI', BlockUI);
    app.component('Button', Button);
    app.component('DatePicker', DatePicker);
    app.component('Card', Card);
    app.component('Chart', Chart);
    app.component('Checkbox', Checkbox);
    app.component('Column', Column);
    app.component('ConfirmDialog', ConfirmDialog);
    app.component('ConfirmPopup', ConfirmPopup);
    app.component('DynamicDialog', DynamicDialog);
    app.component('DataTable', DataTable);
    app.component('DataView', DataView);
    app.component('Dialog', Dialog);
    app.component('Drawer', Drawer);
    app.component('Editor', Editor);
    app.component('Fluid', Fluid);
    app.component('InputMask', InputMask);
    app.component('InputNumber', InputNumber);
    app.component('InputText', InputText);
    app.component('Knob', Knob);
    app.component('Listbox', Listbox);
    app.component('Menu', Menu);
    app.component('Menubar', Menubar);
    app.component('Panel', Panel);
    app.component('ProgressBar', ProgressBar);
    app.component('ProgressSpinner', ProgressSpinner);
    app.component('RadioButton', RadioButton);
    app.component('Rating', Rating);
    app.component('SelectButton', SelectButton);
    app.component('Select', Select);
    app.component('SplitButton', SplitButton);
    app.component('Splitter', Splitter);
    app.component('SplitterPanel', SplitterPanel);
    app.component('Tabs', Tabs);
    app.component('TabList', TabList);
    app.component('Tab', Tab);
    app.component('TabMenu', TabMenu);
    app.component('TabPanels', TabPanels);
    app.component('TabPanel', TabPanel);
    app.component('Textarea', Textarea);
    app.component('Toast', Toast);
    app.component('ToggleSwitch', ToggleSwitch);
    app.component('Toolbar', Toolbar);
    app.component('InputGroup', InputGroup);
    app.component('InputGroupAddon', InputGroupAddon);
  }
}
