import { storeToRefs } from 'pinia';
import { NavigationGuard } from 'vue-router';
import { useAppStore } from '@repo/vue/stores';
import { useClientStore, useFinancialStore, useMenuStore, useRibbonStore, useRootStore, useTabStore } from '@/stores';

export const clientGuard: NavigationGuard = async (to, from, next) => {
  // stores
  const appStore = useAppStore();
  const rootStore = useRootStore();
  const clientStore = useClientStore();
  const tabStore = useTabStore();
  const menuStore = useMenuStore();
  const ribbonStore = useRibbonStore();
  const financialStore = useFinancialStore();

  // state
  const { isIFrame } = storeToRefs(appStore);
  const { practiceId, clientId } = storeToRefs(rootStore);
  const { currentClient } = storeToRefs(clientStore);

  // methods
  const handleDefault = () => {
    if (!isIFrame.value) {
      console.warn('You no longer have a valid client file selected. Try opening client file again.');
      next({ name: 'clients', replace: true });
    } else {
      next();
    }
  };

  const handleProcess = () => {
    if (from.name === 'clients') {
      if (currentClient.value?.id) {
        console.log('Loaded current client from session: ' + currentClient.value.name);

        if (currentClient.value.showStartupTab) {
          const startupTabId = '8c57f9e1-3c60-4dd1-9d07-08ac4427e0ef';
          tabStore.addTabById(startupTabId);
          console.log('Open Client Startup', startupTabId);
          next({ name: 'startup', query: { tabId: startupTabId } });
        } else {
          next();
        }
      } else {
        handleDefault();
      }
    } else {
      next();
    }
  };

  const handleStartup = () => {
    if (from.name === 'clients') {
      if (currentClient.value?.id) {
        const noticeTabId = '64e6113a-f0b9-467c-a5ad-691316996544';
        tabStore.addTabById(noticeTabId);
        console.log('Open Client Notice', noticeTabId);
        next({ name: 'notice', query: { tabId: noticeTabId } });
      } else {
        handleDefault();
      }
    } else {
      next();
    }
  };

  const loadBaseData = async () => {
    if (!currentClient.value?.id) {
      await Promise.all([
        clientStore.fetchCurrentClient(),
        menuStore.fetchMenuItems(),
        ribbonStore.loadRibbonItems(),
        financialStore.fetchFinancialYears()
      ]);
    }
  };

  // guard
  if (practiceId.value && clientId.value) {
    // load base data
    await loadBaseData();

    // handle views
    switch (to.name) {
      case 'process':
        handleProcess();
        break;
      case 'startup':
        handleStartup();
        break;
      default:
        next();
        break;
    }
  } else {
    handleDefault();
  }
};
