import { watchEffect } from 'vue';

/**
 * @ignore
 * Run watchEffect until the watcher returns true, then stop the watch.
 * Once it returns true, the promise will resolve.
 */
export const watchEffectOnceAsync = <T>(watcher: () => T) =>
  new Promise<void>((resolve) => {
    watchEffectOnce(watcher, resolve);
  });

/**
 * @ignore
 * Run watchEffect until the watcher returns true, then stop the watch.
 * Once it returns true, it will call the provided function.
 */
// eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
export const watchEffectOnce = <T>(watcher: () => T, fn: Function) => {
  const stopWatch = watchEffect(() => {
    if (watcher()) {
      fn();
      stopWatch();
    }
  });
};

/**
 * @ignore
 * Helper function to bind methods to itself, useful when using Vue's `provide` / `inject` API's.
 */

export const bindPluginMethods = (plugin: any, exclude: string[]) => {
  // eslint-disable-next-line lodash/prefer-lodash-method
  Object.getOwnPropertyNames(Object.getPrototypeOf(plugin))
    // eslint-disable-next-line lodash/prefer-lodash-method
    .filter((method) => !exclude.includes(method))
    .forEach((method) => (plugin[method] = plugin[method].bind(plugin)));
};
