import * as _ from 'lodash-es';
import { computed, ref } from 'vue';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { RibbonItem } from '@repo/core/models';
import { useDxRibbon, useDxRibbonItem } from '@/utils';
import { useFinancialStore, useTabStore } from '@/stores';
import { useRibbonItemService } from '@repo/core/services';

export const useRibbonStore = defineStore('ribbon', () => {
  // services
  const ribbonItemService = useRibbonItemService();

  // utils
  const dxRibbon = useDxRibbon();
  const dxRibbonItem = useDxRibbonItem();

  // stores
  const tabStore = useTabStore();
  const financialStore = useFinancialStore();

  // state
  const ribbonItems = ref<RibbonItem[]>([]);
  const selectedRibbonItems = ref<RibbonItem[]>([]);
  const ribbonAction = ref<RibbonItem>();

  // getters
  const ribbon = computed(() => tabStore.activeTab?.ribbonItems ?? []);
  const ribbonTabIndex = computed(() => tabStore.activeTab?.$ribbonTabIndex ?? 0);
  const ribbonPeriods = computed(() => _.filter(financialStore.periods, (period) => !!period.showOnRibbon));

  // actions
  const fetchRibbonItems = () => {
    return ribbonItemService.get();
  };

  const loadRibbonItems = async (excludeFinYears = false) => {
    const result = await dxRibbonItem.loadRibbonItems(excludeFinYears);
    ribbonItems.value = result;
    return result;
  };

  const applySelectedRibbonItems = (tabId: string | undefined) => {
    if (!tabId) return;
    const tab = _.find(tabStore.tabs, (t) => _.toLower(t.id) === _.toLower(tabId));
    if (tab) {
      _.forEach(tab.ribbonItems, (a) => {
        _.forEach(a.items, (b) => {
          const ribbonItem = _.find(
            b.items,
            (c) => c.name === (financialStore.currentPeriod?.id || financialStore.selectedPeriod?.id)
          );
          if (ribbonItem) {
            selectedRibbonItems.value = _.filter(
              selectedRibbonItems.value,
              (r) => _.toLower(r.id) !== _.toLower(ribbonItem.id)
            );
            selectedRibbonItems.value.push({ ...ribbonItem });
          }
        });
      });
    }
  };

  const loadRibbon = (tabId: string | undefined) => {
    if (!tabId) return [];
    const result = dxRibbon.buildRibbon(tabId, ribbonItems.value);
    const tab = _.find(tabStore.tabs, (t) => _.toLower(t.id) === _.toLower(tabId));
    if (tab) {
      tab.ribbonItems = result;
    }
    return result;
  };

  const reloadRibbon = async (tabIds: string[]) => {
    if (tabIds) {
      const items = await loadRibbonItems();

      _.forEach(_.isArray(tabIds) ? tabIds : [tabIds], (id) => {
        const tab = _.find(tabStore.tabs, (t) => {
          return _.toLower(t.id) == _.toLower(id);
        });
        if (tab) {
          ribbonItems.value = items;
          tab.ribbonItems = loadRibbon(id);
        }
      });
    }
  };

  const setRibbon = (tabId: string, ribbonItems: RibbonItem[]) => {
    const tab = _.find(tabStore.tabs, (t) => _.toLower(t.id) === _.toLower(tabId));
    if (tab) {
      tab.ribbonItems = ribbonItems;
    }
  };

  const setRibbonAction = (item: RibbonItem) => {
    ribbonAction.value = { ...item };
  };

  const resetRibbonState = () => {
    ribbonItems.value = [];
    ribbonAction.value = {};
    selectedRibbonItems.value = [];
  };

  const state = { ribbonItems, selectedRibbonItems, ribbonAction };
  const getters = { ribbon, ribbonTabIndex, ribbonPeriods };
  const actions = {
    fetchRibbonItems,
    loadRibbonItems,
    applySelectedRibbonItems,
    loadRibbon,
    reloadRibbon,
    setRibbon,
    setRibbonAction,
    resetRibbonState
  };

  return { ...state, ...getters, ...actions };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useRibbonStore, import.meta.hot));
}
