<script setup lang="ts">
import { ComponentPublicInstance, computed, ref, watch } from 'vue';
import { useDxModal } from '@repo/vue/utils';
import { Modal } from 'jenesius-vue-modal';
import Button from 'primevue/button';

// utils
const dxModal = useDxModal();

// types
interface DxModalProps {
  html: string | undefined;
  header: string | undefined;
  btnClose?: string | undefined;
  btnOk?: string | undefined;
  btnCancel?: string | undefined;
  type?: string | undefined;
  size?: string | undefined;
}

// props
const props = withDefaults(defineProps<DxModalProps>(), {
  html: 'Without html',
  header: 'Without header',
  btnClose: 'Close',
  btnOk: 'OK',
  btnCancel: 'Cancel',
  type: 'confirm',
  size: 'md'
});

// emit
const emit = defineEmits([Modal.EVENT_PROMPT, 'confirm']);

// data
const visible = ref<boolean>(true);
const btnOkRef = ref<typeof Button & ComponentPublicInstance>();

// computed
const modalClass = computed(() => props.type);

// methods
const confirm = (choice: boolean) => {
  dxModal.pop();
  emit(Modal.EVENT_PROMPT, choice);
  emit('confirm', choice);
};

// watches
watch(btnOkRef, (newValue, oldValue) => {
  if (newValue && newValue !== oldValue) {
    btnOkRef.value?.$el.focus();
  }
});
</script>

<template>
  <Dialog
    v-model:visible="visible"
    :modal="true"
    :class="modalClass"
    :header="header"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '600px' }"
    :close-on-escape="false"
    @after-hide="confirm(false)"
  >
    <span v-html="html"></span>

    <template #footer>
      <Button ref="btnOkRef" :label="btnOk" @click="confirm(true)" />
      <Button v-if="modalClass !== 'info'" :label="btnCancel" @click="confirm(false)" />
    </template>
  </Dialog>
</template>

<style lang="scss"></style>
