<script setup lang="ts">
import * as _ from 'lodash-es';
import { watch } from 'vue';
import { RouterView } from 'vue-router';
import { storeToRefs } from 'pinia';
import { Icon } from '@iconify/vue';
import { container as WidgetContainerModal } from 'jenesius-vue-modal';
import AppMessage from '@/layout/AppMessage.vue';
import { DxLoader, DxLoaderBar, DxLoaderBlock, DxLoaderSpinner } from '@repo/vue/components';
import { useDxProgress } from '@repo/vue/utils';
import { useAppStore, useAuthStore } from '@repo/vue/stores';
import { ResourceConfig } from '@repo/core/resources';

// utils
const { isLoadingSpinner } = useDxProgress();

// stores
const authStore = useAuthStore();
const appStore = useAppStore();

// state
const { isIFrame } = storeToRefs(appStore);
const { isLoading, user } = storeToRefs(authStore);

// watches
watch(
  () => user.value?.profile?.email,
  (newValue, oldValue) => {
    if (newValue && newValue !== oldValue) {
      if (!_.endsWith(_.toLower(newValue), _.toLower('@draftworx.com')) && !isIFrame.value) {
        location.href = ResourceConfig.webAngularUrl;
      }
    }
  },
  { immediate: true }
);
</script>

<template>
  <template v-if="isLoading">
    <DxLoader />
  </template>
  <template v-else>
    <RouterView />
    <AppMessage v-if="isIFrame" />
    <DxLoaderBlock :blocked="isLoadingSpinner" />
    <DxLoaderBar v-if="!isIFrame" />
    <DxLoaderSpinner />
    <Toast />
    <ConfirmDialog>
      <template #message="slotProps">
        <Icon icon="line-md:confirm" :class="slotProps.message.icon" />
        <span class="p-confirm-dialog-message" v-html="slotProps.message.message"></span>
      </template>
    </ConfirmDialog>
    <DynamicDialog />
    <WidgetContainerModal />
  </template>
</template>

<style scoped lang="scss" />
