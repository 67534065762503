import * as _ from 'lodash-es';
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { RouteLocationNamedRaw, useRoute, useRouter } from 'vue-router';
import { EventData, TabData } from '@repo/core/models';
import { ResourceConfig } from '@repo/core/resources';
import { EventConstants, MessageConstants } from '@repo/core/constants';
import { useClientStore, useEventStore, useFinancialStore, useMenuStore, useRootStore, useTabStore } from '@/stores';
import { useDxEventBus, useDxMessage, useDxToast } from '@repo/vue/utils';
import { useAppStore, useAuthStore } from '@repo/vue/stores';

export const useDxState = () => {
  // stores
  const authStore = useAuthStore();
  const appStore = useAppStore();
  const rootStore = useRootStore();
  const eventStore = useEventStore();
  const clientStore = useClientStore();
  const tabStore = useTabStore();
  const financialStore = useFinancialStore();
  const menuStore = useMenuStore();

  // state
  const { isIFrame } = storeToRefs(appStore);
  const { eventData } = storeToRefs(eventStore);
  const { currentClient } = storeToRefs(clientStore);
  const { tabs, activeTabIndex } = storeToRefs(tabStore);

  // computed
  const target = computed(() => window.parent);

  // utils
  const router = useRouter();
  const route = useRoute();
  const dxToast = useDxToast();
  const dxEventBus = useDxEventBus();
  const dxMessage = useDxMessage(target, ResourceConfig.webAngularUrl);

  // methods
  const sendMessage = (data: EventData) => {
    dxMessage.sendMessage(data);
  };

  const go = async (to: RouteLocationNamedRaw) => {
    if (to.name && router.hasRoute(to.name)) {
      await router.push(to);
    } else {
      dxToast.error(`Page ${to.name?.toString()} not found`);
      return;
    }
    if (isIFrame.value) {
      const tabId = to.query?.tabId?.toString();
      const message = { ...eventData.value };
      message.event = tabId ? MessageConstants.loadTab : MessageConstants.loadPage;
      message.page = to.name?.toString();
      message.tabId = tabId;
      message.practiceId = rootStore.practiceId;
      message.clientId = rootStore.clientId;
      message.financialYearId = rootStore.financialYearId;
      message.supportAccessRequestId = rootStore.supportAccessRequestId;
      dxMessage.sendMessage(message);
    }
  };

  const init = () => {
    console.log('vue:init');
    rootStore.clearCurrentClient();
    rootStore.clearCurrentPractice();
    rootStore.clearCurrentTicket();
    rootStore.clearCurrentYear();
    rootStore.clearCurrentTabs();
  };

  const login = async () => {
    if (isIFrame.value) {
      const message = { ...eventData.value };
      message.event = MessageConstants.login;
      dxMessage.sendMessage(message);
    } else {
      await authStore.loginWithRedirect();
    }
  };

  const logout = async () => {
    if (isIFrame.value) {
      const message = { ...eventData.value };
      message.event = MessageConstants.logout;
      dxMessage.sendMessage(message);
    } else {
      await authStore.logout();
    }
  };

  const reload = async () => {
    tabStore.resetTabState();
    financialStore.resetFinancialState();
    if (currentClient.value?.id) {
      await openClient(currentClient.value?.id);
    } else {
      await go({ name: 'clients' });
    }
  };

  const openClient = async (clientId: string) => {
    clientStore.openClient(clientId);
    await go({ name: 'process' });
  };

  const loadPage = async (page: string) => {
    await router.push({ name: page, replace: true });
  };

  const loadTab = async (page: string, tabId: string) => {
    await router.push({ name: page, query: { tabId: tabId }, replace: true });
  };

  const addTab = async (data: TabData) => {
    if (!data) return;
    tabStore.addTab(data);

    if (data.$name && router.hasRoute(data.$name)) {
      await router.push({
        name: data.$name,
        query: { tabId: data.id }
      });
    } else {
      await router.push({
        name: 'process'
      });
    }
  };

  const removeTab = async (tabId: string) => {
    // Find the tab to be closed
    const tab = _.find(tabs.value, (t) => _.toLower(t.id) === _.toLower(tabId));
    const closeTabIndex = _.indexOf(tabs.value, tab);
    const currentTabIndex = activeTabIndex.value;

    // If the tab being closed is the active tab, calculate the new tab index
    let newTabIndex = currentTabIndex;

    // If the closed tab is the active tab, calculate the new tab index
    if (closeTabIndex === currentTabIndex) {
      // Move to the next tab if possible
      newTabIndex = currentTabIndex + 1 < tabs.value.length ? currentTabIndex + 1 : currentTabIndex - 1;
    }

    // Ensure valid bounds for newTabIndex
    newTabIndex = Math.max(newTabIndex, 0);

    // Navigate to the new tab if there are other tabs available
    if (tabs.value.length > 1) {
      const newTab = tabs.value[newTabIndex]; // Use pre-calculated newTabIndex
      await router.push({ name: newTab.$name, query: { tabId: newTab.id } });
    } else {
      await router.push({ name: 'process' }); // Fallback route if no tabs left
    }

    // Remove the tab from the store AFTER redirection is completed
    tabStore.removeTab(tabId);

    // Send remove tab message if in iFrame
    if (isIFrame.value) {
      const message = { ...eventData.value };
      message.event = MessageConstants.removeTab;
      message.tabId = tabId;
      sendMessage(message);
    }
  };

  const selectedYearChanged = async (financialYearId: string) => {
    await financialStore.loadFinancialOverviewData(financialYearId);
  };

  const closeClient = async () => {
    await router.push({ name: 'process' });

    // clear current tabs
    rootStore.clearCurrentTabs();

    // Send close client message if in iFrame
    if (isIFrame.value) {
      const message = { ...eventData.value };
      message.event = MessageConstants.closeClient;
      sendMessage(message);
    }
  };

  const reloadClientMenu = async () => {
    await menuStore.fetchMenuItems();
    dxEventBus.emit(EventConstants.clientMenuUpdated);
  };

  return {
    router,
    route,
    isIFrame,
    sendMessage,
    go,
    init,
    login,
    logout,
    reload,
    openClient,
    addTab,
    loadPage,
    loadTab,
    removeTab,
    selectedYearChanged,
    closeClient,
    reloadClientMenu
  };
};
