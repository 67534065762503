import * as _ from 'lodash-es';
import { AxiosInstance } from 'axios';
import { HeaderConstants } from '@repo/core/constants';
import { useAuthService } from '@repo/core/security';

export const setRequestInterceptor = (instance: AxiosInstance, prefix = 'Bearer') => {
  instance.interceptors.request.use(
    async (config) => {
      config.headers = config.headers ?? {};

      const authService = useAuthService();
      const token = await authService.getToken();
      if (token) {
        config.headers.Authorization = `${prefix} ${token}`;
      }

      if (sessionStorage.practiceId) {
        config.headers[HeaderConstants.PracticeId] = sessionStorage.practiceId;
      }
      if (sessionStorage.clientId) {
        config.headers[HeaderConstants.ClientId] = sessionStorage.clientId;
      }
      if (sessionStorage.supportAccessRequestId) {
        config.headers[HeaderConstants.SupportAccessRequestId] = sessionStorage.supportAccessRequestId;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export const setResponseInterceptor = (instance: AxiosInstance) => {
  instance.interceptors.response.use(
    (response) => {
      // console.log(
      //   `${response.config.baseURL}${response.config.url}, status: ${response.status}, method: ${response.config.method}`
      // );
      return response;
    },
    async (error) => {
      const response = error.response;
      if (response) {
        if (error.config.responseType === 'blob') {
          const text = await error.response.data.text();
          response.data = JSON.parse(text);
        }

        if (response?.status === 400) {
          if (response.data.message) {
            response.data.message = _.replace(response.data.message, /\r\n|\r|\n/g, '<br />');
          }
          if (response.data.errors) {
            const data = response.data;
            for (let i = 0; i < response.data.errors.length; i++) {
              if (_.isArray(data) && data[i]) data[i].$errors = response.data.errors[i];
              else data.$errors = response.data.errors[i];
            }
          }
        }
      }
      return Promise.reject(error);
    }
  );
};
