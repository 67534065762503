import { ComputedRef, onBeforeUnmount, onMounted, ref, shallowRef } from 'vue';

export const useDxMessage = (target: ComputedRef<Window | null | undefined>, targetOrigin: string) => {
  // data
  const event = ref<MessageEvent>();
  const data = ref<any>();
  const error = shallowRef<MessageEvent | null>(null);

  // events
  const onMessage = (e: MessageEvent) => {
    if (e.origin !== targetOrigin) return;
    event.value = e;
    data.value = e.data;
  };
  const onMessageError = (e: MessageEvent) => {
    error.value = e;
  };

  // methods
  const sendMessage = (data: any) => {
    if (target.value && targetOrigin) {
      const message = JSON.parse(JSON.stringify(data));
      console.log('vue:sendMessage', message);
      target.value.postMessage(message, targetOrigin);
    }
  };

  // hooks
  onMounted(() => {
    window.addEventListener('message', onMessage);
    window.addEventListener('messageerror', onMessageError);
  });
  onBeforeUnmount(() => {
    window.removeEventListener('message', onMessage);
    window.removeEventListener('messageerror', onMessageError);
  });

  return {
    event,
    data,
    error,
    sendMessage
  };
};
